import {
  detachMindmap,
  hideTargetConnector,
  moveMindMapSubtree,
  showMergeIndicator,
} from './utils.js';

export const MindmapUtils = {
  detachMindmap,
  hideTargetConnector,
  moveMindMapSubtree,
  showMergeIndicator,
};
