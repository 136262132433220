/** column default width */
export const DEFAULT_COLUMN_WIDTH = 180;
/** column min width */
export const DEFAULT_COLUMN_MIN_WIDTH = 100;
/** column title height */
export const DEFAULT_COLUMN_TITLE_HEIGHT = 36;
/** column title height */
export const DEFAULT_ADD_BUTTON_WIDTH = 40;
export const LEFT_TOOL_BAR_WIDTH = 24;
export const STATS_BAR_HEIGHT = 34;
